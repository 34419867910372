import React, { useState, useEffect } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import ScannerScreen from "./screens/scannerScreen";
import LoginScreen from "./screens/loginScreen";

function App() {
  const [localToken, setLocalToken] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = localStorage.getItem("login_token");
        if (token) {
          setLocalToken(token);
        } else {
          setLocalToken(null);
        }
      } catch (error) {
        console.error("Error fetching token:", error);
        setLocalToken(null);
      }
    };

    fetchToken();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginScreen />}/>
        <Route path="/home" element={<HomeScreen />}/>
        <Route path="/scanner" element={<ScannerScreen />}/>
        <Route
          path="/"
          element={localToken ? <HomeScreen /> : <LoginScreen />}
          />
      </Routes>
    </Router>
  );
}

export default App;
