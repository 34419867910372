import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { QrReader } from "react-qr-reader";
import { useNavigate, Link } from "react-router-dom";
import logo from "../assets/s-sellercloud-connect-black-blue-1@3x.png";

const LoginScreen = () => {
  const spreadsheetId = "1kQ-SWmi1iah-SuZ8TK5nH6-pGctSS0yVoFXFEHkdh6g";

  const [showScanner, setShowScanner] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [barcode, setBarcode] = useState("");
  const [user, setUser] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await localStorage.getItem("login_token");
        console.log("login  " + token);
        if (token != null) {
          console.log(token);
          navigate("/");
          // window.location.reload();
        }
      } catch (error) {
        console.log("Error fetching token:", error);
      }
    };

    fetchToken();
  }, [navigate, forceUpdate]);

  const handleLogin = async (scannedBarcode) => {
    console.log("Scanned Barcode:", scannedBarcode.text);
    setBarcode(scannedBarcode.text);
    setShowScanner(false);
    setLoading(true);

    try {
      const userName = await fetchUserInfo(scannedBarcode.text);

      console.log(userName);

      if (userName) {
        const userExists = await doesUserExist(scannedBarcode.text);
        console.log("user exists: " + userExists);
        if (!userExists) {
          // create a user
          const userCreated = await createUser(scannedBarcode.text);
          if (!userCreated) {
            return;
          }
        }

        setUser(userName);

        setIsDialogOpen(true);
      } else {
        console.error("Failed to fetch user information, invalid barcode");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error handling login:", error);
      setLoading(false);
    }
  };

  const createUser = async (barcodeData) => {
    var name = "";
    var company = "";
    var email = "";
    var title = "";

    try {
      const response = await fetch(
        `https://contact-list-yf14.onrender.com/fetch-user-info/${barcodeData}`
      );

      const result = await response.json();
      console.log("result from fetchUserInfo:", result.result);

      if (
        result.result &&
        result.result.data &&
        result.result.data.length > 0
      ) {
        const buyerDetails = result.result.data[0].buyer_details;
        const companyName = buyerDetails.custom_questions[0].answer;

        company = companyName;

        console.log("company: " + company);

        const issuedTickets = result.result.data[0].issued_tickets;

        console.log("tickets: " + issuedTickets);

        for (let i = 0; i < issuedTickets.length; i++) {
          if (issuedTickets[i].barcode.toLowerCase() === barcodeData.toLowerCase()) {
            title = issuedTickets[i].custom_questions[0].answer;
            email = issuedTickets[i].custom_questions[1].answer;
            name = issuedTickets[i].full_name;
          }
        }
      }

      if (company !== "" && name !== "" && email !== "" && title !== "") {
        try {
          await createSheetFromBarcode(
            barcodeData,
            company,
            name,
            email,
            title
          );
          return true;
        } catch (error) {
          alert("There is already a user for this Ticket, go back and LogIn");
          return false;
        }
      } else {
        console.log(title + " " + name + " " + email);
        alert("Invalid barcode.");
        return false;
      }
    } catch (error) {
      console.error(error.message);
      alert("API call failed");
      return false;
    }
  };

  const createSheetFromBarcode = async (
    barcode,
    company,
    username,
    email,
    title
  ) => {
    try {
      const sheetName = barcode;

      const url = `https://contact-list-yf14.onrender.com/create-user`;

      const requestBody = {
        spreadsheetId: spreadsheetId,
        tabName: barcode,
        values: [[barcode, username, email, company, title]],
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      console.log(response);

      if (response.ok) {
        const responseData = await response.json();
        console.log(`Sheet ${sheetName} added successfully!`, responseData);
      } else {
        alert("Failed to create sheet");
      }
    } catch (error) {
      console.error("Error adding user:", error);
      throw error;
    }
  };

  const doesUserExist = async (barcode) => {
    const response = await fetch(
      `https://contact-list-yf14.onrender.com/get-contacts/${spreadsheetId}/${barcode}`
    );
    console.log(response);
    return response.ok;
  };

  const handleEmailPress = async () => {
    const email = await fetchUserEmail();

    if (email != null) {
      if (email.toLowerCase() === emailInput.toLowerCase()) {
        setIsDialogOpen(false);
        console.log(
          "email: " + email + " is correct, now we will navigate to home"
        );

        const updatedToken = {
          barcode: barcode,
          userName: user,
        };

        await localStorage.setItem("login_token", JSON.stringify(updatedToken));

        window.location.reload();
      } else {
        alert("invalid email address");
      }
    }
  };

  const fetchUserEmail = async () => {
    try {
      const response = await fetch(
        `https://contact-list-yf14.onrender.com/fetch-user-info/${barcode}`
      );

      const result = await response.json();
      console.log("result from fetchUserInfo:", result.result);

      if (
        result.result &&
        result.result.data &&
        result.result.data.length > 0
      ) {
        const issuedTickets = result.result.data[0].issued_tickets;

        for (let i = 0; i < issuedTickets.length; i++) {
          if (issuedTickets[i].barcode.toLowerCase() === barcode.toLowerCase()) {
            return issuedTickets[i].custom_questions[1].answer;
          }
        }
      }
      // return null;
    } catch (error) {
      console.error(error.message);
      alert("Error", "API call failed, barcode not valid");
    }
  };

  const fetchUserInfo = async (barcode) => {
    try {
      const response = await fetch(
        `https://contact-list-yf14.onrender.com/fetch-user-info/${barcode}`
      );

      const result = await response.json();
      console.log("result from fetchUserInfo:", result.result);

      if (
        result.result &&
        result.result.data &&
        result.result.data.length > 0
      ) {
        const issuedTickets = result.result.data[0].issued_tickets;

        for (let i = 0; i < issuedTickets.length; i++) {
          if (issuedTickets[i].barcode === barcode) {
            return issuedTickets[i].full_name;
          }
        }
      }
      // return null;
    } catch (error) {
      console.error(error.message);
      alert("Error", "API call failed, barcode not valid");
    }
  };

  const handleScanResult = (result) => {
    if (result) {
      handleLogin(result);
    }
  };

  const handleScanButtonClick = () => {
    setShowScanner(true);
  };

  return (
    <div className="login-container">
      <>
        <img src={logo} alt="Profile" className="sc-connect-logo" />
        {showScanner ? (
          <>
            <p className="login-instructions">Scan Your Name Tag to Login:</p>
            <QrReader
              delay={300}
              onError={(error) => console.error("QR Scanner Error:", error)}
              key="environment"
              constraints={{ facingMode: "environment" }}
              onResult={handleScanResult}
              className="login-qrcode-reader"
            />
          </>
        ) : (
          <>
            <span className="Welcome-to-Sellercloud-User-Conference">
              Welcome to Sellercloud Connect 2024!
            </span>
            <button onClick={handleScanButtonClick} className="Principal-cta">
              {loading ? "loading..." : "Login"}
            </button>
          </>
        )}
      </>

      <Dialog className="email-confirmation-dialog" open={isDialogOpen}>
        <h2 className="email-dialog-header">Confirm Your Account</h2>
        <TextField
          className="dialog-input"
          label="Email Address"
          type="email"
          value={emailInput}
          onChange={(e) => setEmailInput(e.target.value)}
        />
        <div className="email-dialog-buttons">
          <Button
            className="email-dialog-cancel"
            onClick={() => setIsDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button className="email-dialog-ok" onClick={handleEmailPress}>
            Ok
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default LoginScreen;
