import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { QrReader } from "react-qr-reader";

const spreadsheetId = "1kQ-SWmi1iah-SuZ8TK5nH6-pGctSS0yVoFXFEHkdh6g";

const ScannerScreen = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); 

  const handleApiCall = async (barcodeData) => {

    var local_token = await localStorage.getItem("login_token");

    local_token = JSON.parse(local_token);

    var name = "";
    var email = "";
    var companyName = "";
    var title = "";

    setLoading(true);

    try {
        const ttresponse = await fetch(
            `https://contact-list-yf14.onrender.com/fetch-user-info/${barcodeData.text}`
          );
    
          const result = await ttresponse.json();
          console.log("result from fetchUserInfo:", result.result);

      if ( result.result &&  result.result.data &&  result.result.data.length > 0) {
        const buyerDetails =  result.result.data[0].buyer_details;
        const company = buyerDetails.custom_questions[0].answer;

        companyName = company;

        const issuedTickets =  result.result.data[0].issued_tickets;

        for (let i = 0; i < issuedTickets.length; i++) {
          if (issuedTickets[i].barcode.toLowerCase() === barcodeData.text.toLowerCase()) {
            title = issuedTickets[i].custom_questions[0].answer;
            email = issuedTickets[i].custom_questions[1].answer;
            name = issuedTickets[i].full_name;
          }
        }
      }

      const sheetName = local_token.barcode;
  
      const url = `https://contact-list-yf14.onrender.com/add-contact`;

      const requestBody = {
        "spreadsheetId": spreadsheetId,
        "tab": sheetName ,
        "values": [
          [name, email, companyName, title , 0]   
        ]
      };

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
  
  
      console.log(response);

      if(response.ok){
        console.log("contact added successfully.")
      } else if(response.status == 400){
        alert("This contact has already been added to your list.");
      }else{
        console.error("error adding contact.");
      }
    
      setLoading(false);
      navigate("/");
    } catch (error) {
      console.error(error.message);
      alert("Error", "API call failed");
      setLoading(false);
    }
  };

  const handleScanResult = (result) => {
    if (result) {
        handleApiCall(result);
    }
  };

  return (
    <div >
      <h4>
        Scan the name tag of the person you would like to add to your Contacts.
      </h4>

      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <QrReader
            delay={300}
            onError={(error) => console.error("QR Scanner Error:", error)}
            key="environment"
            constraints={{ facingMode: "environment" }}
            onResult={handleScanResult}
            style={{ width: "100%" }}
            zoom={2}
          />
      )}
    </div>
  );
};



export default ScannerScreen;
